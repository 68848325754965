/**
 * @author Karl Jones
 * @email karljones@live.ie
 * @create date 2022-12-01 13:52:03
 * @modify date 2022-12-10 22:36:25
 */

import { lazy } from 'react';

import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { theme } from './config/Theme';
import { ThemeProvider } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';

import Layout from './components/pages/Layout';
import Home from './components/pages/home/Home';
import Books from './components/pages/books/Books';
import Podcast from './components/pages/podcast/Podcast';
const Blog = lazy(() => import('./components/pages/blog/Blog'));
const BlogSearch = lazy(() => import('./components/pages/blogsearch/BlogSearch'));
const Contact = lazy(() => import('./components/pages/contact/Contact'));
const Article = lazy(() => import('./components/pages/article/Article'));
const Book = lazy(() => import('./components/pages/book/Book'));
const Privacy = lazy(() => import('./components/pages/privacy/Privacy'));
const NotFound = lazy(() => import('./components/pages/notfound/NotFound'));

function App() {
  return (
    <Router>
      <ThemeProvider theme={theme}>
        <SnackbarProvider maxSnack={3} preventDuplicate>
          <div data-testid="App" className="App">
            <Routes>
              <Route path="/" element={<Layout />} >
                <Route path="/" element={<Home />} />
                <Route path="/books" element={<Books />} />
                <Route path="/books/:shortcode" element={<Book />} />
                {/* <Route path="/podcasts/:shortcode" element={<Podcast />} /> */}
                <Route path="/blog" element={<Blog />} />
                <Route path="/blog/search" element={<BlogSearch />} />
                <Route path="/blog/:pageToken" element={<Blog />} />
                <Route path="/blog/article/:postId" element={<Article />} />
                <Route path="/etsy" element={<Navigate to="https://www.etsy.com/uk/shop/The2littlebugs?ref=paigehart-site-navigation" />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/privacy" element={<Privacy />} />
                <Route path="*" element={<NotFound />} />
              </Route>
            </Routes>
          </div>
        </SnackbarProvider>
      </ThemeProvider>
    </Router>
  );
}

export default App;
